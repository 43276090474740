export const pageFrom = data => {
  return 1 + (data.page - 1) * data.recordsperpage;
};
export const pageTo = (data, recordsPerPage) => {
  const to = recordsPerPage + (data.page - 1) * data.recordsperpage;
  return to > data.count ? data.count : to;
}; /*
   export const findFilter = (filtersId_, filterKey, filterValue) => {
      if (filterValue < 0) {
          return undefined;
      }
      return filtersId_
          .find(map => map.get('type').toLowerCase() === filterKey.toLowerCase()).get("items")
          .find(d => d.get('id') === filterValue).get('value');
   }*/

export const API_FILTERS_TO_AMP_FILTERS = new Map([['donor', 'donor-agency'], ['country', 'administrative-level-0'], ['pillar', 'national-planning-objectives-level-1'], ['projectStatus', 'status']]);
export const API_FILTERS_TO_SCHEME = new Map([['donor', 'donor'], ['country', 'locations'], ['pillar', 'nationalPlanningObjectives'], ['projectStatus', 'activityStatus'], ['currency', 'currency-code']]);