/* eslint-disable no-undef */
import * as api from './api/index';
export const LOAD_AMP_SETTINGS = 'LOAD_AMP_SETTINGS';
export const LOAD_AMP_SETTINGS_DONE = 'LOAD_AMP_SETTINGS_DONE';
export const LOAD_AMP_SETTINGS_ERROR = 'LOAD_AMP_SETTINGS_ERROR';
export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_DATA_DONE = 'LOAD_DATA_DONE';
export const LOAD_DATA_ERROR = 'LOAD_DATA_ERROR';
export const loadAmpSettings = () => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  dispatch({
    type: LOAD_AMP_SETTINGS
  });
  return api._loadAMpSettings(api_root).then(data => {
    return dispatch({
      type: LOAD_AMP_SETTINGS_DONE,
      data: data
    });
  }).catch(error => {
    return dispatch({
      type: LOAD_AMP_SETTINGS_ERROR,
      error
    });
  });
};
export const setDataProvider = ({
  csv,
  store,
  params
}) => (dispatch, getState) => {
  const filters = getState().get('data').getIn(['filters']);
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  const data = Papa.parse(csv, {
    header: true
  });
  dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  });
};
export const getDataProvider = ({
  app,
  source,
  store,
  params,
  measure,
  dateFilter,
  locale,
  keyword
}) => (dispatch, getState) => {
  const filters = getState().get('data').getIn(['filters']);
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  dispatch({
    type: LOAD_DATA,
    params,
    store
  });
  api.getData(source, params, app, measure, dateFilter, locale, keyword, api_root).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store,
    error
  }));
};
export const loadSearchData = ({
  filters,
  keyword,
  page,
  pageSize,
  store,
  currency,
  isDownload
}) => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  const locale = 'en';
  let newStore = `${store}${isDownload ? '-download' : ''}`;
  dispatch({
    type: LOAD_DATA,
    store: newStore
  });
  api.searchActivities(filters, keyword, page, pageSize, currency, isDownload, locale, api_root).then(data => {
    return dispatch({
      type: LOAD_DATA_DONE,
      store: newStore,
      data
    });
  }).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store: newStore,
    error
  }));
};
export const loadFilters = ({
  filterArray,
  store,
  locale
}) => (dispatch, getState) => {
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  filterArray.forEach(filter => {
    const newStore = store + '_' + filter;
    dispatch({
      type: LOAD_DATA,
      store: newStore
    });
    api.loadAmpFilters(filter, locale, api_root).then(data => dispatch({
      type: LOAD_DATA_DONE,
      store: newStore,
      data
    })).catch(error => dispatch({
      type: LOAD_DATA_ERROR,
      store: newStore,
      error
    }));
  });
};
export const loadIndicatorData = ({
  country,
  objectives,
  program,
  objective,
  subPillar,
  filterBySubPillar,
  store
}) => (dispatch, getState) => {
  const newStore = `${store}`;
  const api_root = getState().getIn(['wordpress', 'settings', 'data'])['react_api_url'];
  dispatch({
    type: LOAD_DATA,
    store: newStore
  });
  api.fetchIndicators({
    country,
    objectives,
    program,
    objective,
    subPillar,
    filterBySubPillar,
    api_root
  }).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store: newStore,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store: newStore,
    error
  }));
};